import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { Container } from 'react-bootstrap';
import dayjs from 'dayjs';
import _ from 'lodash';

import Filters from './Filters';
import ErrorMessage from '../../../errorMessage';
import { formatDateTime, formatDateForBigQuery, formatDateTimeForBigQuery } from '../../../../functions/formatDate';
import { DataTable, TableFooter } from '../../../widgets/DataTable';
import useTableRequest from '../../../../functions/useTableRequest';
import fetchWithJWT from '../../../../functions/fetchWithJWT';
import { handlePaginatedApiResponse } from '../../../../functions/handleApiResponse';
import getQueryString from '../../../../functions/getQueryString';
import formatMoney from '../../../../functions/formatMoney';
import getFiltersWithInverted from '../../../../functions/getFiltersWithInverted';
import { useUserFilters } from '../../../../functions/filtersKeeper';
import '../../../../stylesheets/warehouseReportingDiscrepancies.css';

const PAGE_NAME = 'wrongStore';

const getReport = (user, updateTokens, { filters, pagination, sort }, warehouse) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const formatedFilters = { ...filters };
  if (warehouse) {
    formatedFilters.warehouse = warehouse;
  }
  if (formatedFilters.receptionDateFrom) {
    formatedFilters.receptionDateFrom = formatDateForBigQuery(formatedFilters.receptionDateFrom);
  }
  if (formatedFilters.receptionDateTo) {
    formatedFilters.receptionDateTo = formatDateTimeForBigQuery(formatedFilters.receptionDateTo);
  }
  const queryString = getQueryString({
    filters: formatedFilters,
    pagination,
    sort,
  })

  const url = `${process.env.REACT_APP_base_URL}/api/warehouse/${warehouse}/discrepancies/unexpected/matches?${queryString}`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handlePaginatedApiResponse);
};


const getFields = ({ t }) => [
  {
    title: t('unexpectedMatches.columns.warehouse', 'Warehouse'),
    field: 'warehouse',
    sortable: true,
  },
  {
    title: t('unexpectedMatches.columns.store', 'Store unexpected'),
    field: 'store',
    sortable: true,
  },
  {
    title: t('unexpectedMatches.columns.matchingStore', 'Store expected'),
    field: 'matchingStore',
    sortable: true,
  },
  {
    title: t('unexpectedMatches.columns.epc', 'EPC'),
    field: 'epc',
    sortable: true,
  },
  {
    title: t('unexpectedMatches.columns.delivery', 'Delivery'),
    field: 'delivery',
    sortable: true,
  },
  {
    title: t('unexpectedMatches.columns.date', 'Date'),
    field: 'receptionDate',
    sortable: true,
    valueRenderer: (val) => val ? formatDateTime(new Date(val)) : null,
  },
  {
    title: t('unexpectedMatches.columns.item', 'Item'),
    field: 'item',
    sortable: true,
  },
  {
    title: t('unexpectedMatches.columns.modelId', 'Model Id'),
    field: 'modelId',
    sortable: true,
  },
  {
    title: t('unexpectedMatches.columns.rfid', 'RFID'),
    field: 'articleFlag',
    sortable: true,
  },
  {
    title: t('unexpectedMatches.columns.price', 'Selling price'),
    field: 'price',
    align: 'right',
    sortable: true,
    valueRenderer: (val, field, row) => formatMoney(val, row.currency, '-'),
  },
  {
    title: t('unexpectedMatches.columns.cessionPrice', 'Cession price'),
    field: 'cessionPrice',
    align: 'right',
    sortable: true,
    valueRenderer: (val, field, row) => formatMoney(val, row.cessionCurrency, '-'),
  },
  {
    title: t('unexpectedMatches.columns.itemLib', 'Item description'),
    field: 'itemLib',
    sortable: true,
  },
  {
    title: t('unexpectedMatches.columns.unexpected', 'Unexpected'),
    field: 'unexpectedQty',
    sortable: true,
  },
];


const WrongStore = ({ user, updateTokens }) => {
  const { t } = useTranslation();
  const { warehouse } = useParams();
  const initialFilters = {
    receptionDateFrom: dayjs().startOf('day').subtract(7, 'day').toDate(),
    receptionDateTo: dayjs().endOf('day').toDate(),
  };

  const [{
    loading,
    data,
    error,
    filters,
    pagination,
    sort,
    totals,
  }, fetchReport, setTableFilters, setPagination, setSort] = useTableRequest(
    getReport, {
      initialState: {
        filters: initialFilters,
      }
    }
  );

  const filtersPacking = (filters, invertedFilters) => ({
    filters: _.omit(filters, ['receptionDateFrom', 'receptionDateTo']),
    invertedFilters: invertedFilters || {},
  });
  const filtersUnpacking = (saved) => [saved.filters, saved.invertedFilters || {}];

  const {
    isLoading: isLoadingFilters,
    error: actionError,
    setFilters,
    invertedFilters,
    setInvertedFilters,
  } = useUserFilters({
    page: PAGE_NAME,
    user,
    updateTokens,
    initial: {
      filters: initialFilters,
    },
    setTableFilters,
    filtersPacking,
    filtersUnpacking,
  });

  useEffect(() => {
    if (isLoadingFilters) {
      return;
    }

    fetchReport(user, updateTokens, {
      filters: getFiltersWithInverted(filters, invertedFilters),
      pagination,
      sort,
    }, warehouse);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingFilters, warehouse, filters, invertedFilters, pagination, sort]);

  return (
    <Container className="warehouseUnexpectedMatches" fluid>
      <h2>
        <Trans i18nKey="unexpectedMatches.title">Tags send to wrong store</Trans>
      </h2>
      {!!actionError && <ErrorMessage error={actionError} />}
      <Filters
        user={user}
        updateTokens={updateTokens}
        filters={filters}
        totals={totals}
        sort={sort}
        setFilters={setFilters}
        onClear={() => {
          setFilters(initialFilters);
          setInvertedFilters({});
        }}
        invertedFilters={invertedFilters}
        setInvertedFilters={setInvertedFilters}
      />
      <DataTable
        data={data}
        fields={getFields({ t })}
        loading={loading}
        error={error}
        sort={sort}
        setSort={setSort}
      />
      <TableFooter
        pagination={pagination}
        totals={totals}
        setPagination={setPagination}
      />
    </Container>
  )
}

export default WrongStore;
