import React, { useCallback } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next'
import useRequest from '../../functions/useRequest';
import fetchWithJWT from '../../functions/fetchWithJWT';
import handleApiResponse from '../../functions/handleApiResponse';
import '../../stylesheets/fileEditor.css';

export const postFile = (user, updateTokens, file, dir) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/files`;
  const body = new FormData();
  body.append('file', file);
  body.append('dir', dir);
  return fetchWithJWT(url, {
    method: 'POST',
    headers: {},
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    },
    body,
  })
  .then(handleApiResponse);
};

export const UploadFile = ({ disabled, accept, onUpload }) => {
  const onDrop = useCallback(acceptedFiles => {
    if (acceptedFiles && acceptedFiles[0]) {
      onUpload(acceptedFiles[0])
    }
  }, [onUpload]);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    disabled,
    accept,
  });

  const uploadClass = isDragActive ? 'edit-file-upload-button-over' : '';

  return (
    <div
      role="button"
      title="Upload"
      className={`btn btn-lg btn-outline-primary edit-file-upload-button ${uploadClass}`}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <i className="vtmn-icon_arrow1_up"></i>
    </div>
  );
};

const getExtension = (url) => {
  const filePath = url.split('?')[0];
  const pathParts = filePath.split('/');
  const fileName = pathParts[pathParts.length - 1];
  const extension = (fileName.split('.')[1] || '').toUpperCase();
  return extension;
};

const EditFile = ({
  id,
  url,
  loading,
  onDelete,
  onSelect,
  accept,
}) => {
  const { t } = useTranslation();
  if (loading) {
    return (
      <div className="edit-file-wrapper">
        <Spinner />
      </div>
    );
  }

  const extension = getExtension(url);

  return (
    <div className="edit-file-wrapper">
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        className="edit-file-file-icon"
      >
        <i className="vtmn-icon_document"></i>
        <div className="edit-file-file-extension">{extension}</div>
      </a>
      <Button
        title={t('fileEditor.remove', 'Remove')}
        variant="outline-primary"
        className="edit-file-delete-button"
        onClick={onDelete}
      >
        <i className="vtmn-icon_delete"></i>
      </Button>
      <label
        role="button"
        title={t('fileEditor.edit', 'Edit')}
        className="btn btn-outline-primary edit-file-edit-button"
        htmlFor={`file-input-${id}`}
      >
        <i className="vtmn-icon_edit"></i>
        <input
          id={`file-input-${id}`}
          type="file"
          accept={accept}
          onChange={(e) => {
            if (e.target.files && e.target.files.length) {
              const file = e.target.files[0];
              onSelect(file);
            }
          }}
        />
      </label>
      <a href={url} target="_blank" rel="noopener noreferrer">
        <Button
          title={t('fileEditor.download', 'Download')}
          variant="outline-primary"
          className="edit-file-download-button"
        >
          <i className="vtmn-icon_download"></i>
        </Button>
      </a>
    </div>
  );
}

const FilePicker = ({
  id,
  file,
  removeFile,
  uploadFile,
  accept,
}) => (
  file.url ? (
    <EditFile
      id={id}
      url={file.url}
      loading={file.loading}
      onDelete={() => removeFile(file.url)}
      onSelect={uploadFile}
      accept={accept}
    />
  ) : (
    <UploadFile
      disabled={file.loading}
      onUpload={uploadFile}
      accept={accept}
    />
  )
);



const FileEditor = ({
  user,
  updateTokens,
  id,
  url,
  setUrl,
  setUploading,
  accept,
  directory,
}) => {
  const { token } = user;
  const [{
    loading: uploading,
    error,
  }, uploadFile] = useRequest(postFile, {
    onSuccess: ({ path }) => setUrl(path),
  });
  const { t } = useTranslation();
  const dir = directory ? directory : 'tmp';
  return (
    <>
      <FilePicker
        id={id}
        file={{
          url: url && `${process.env.REACT_APP_base_URL}/api/files/${url}?token=${token}`,
          loading: uploading,
        }}
        removeFile={() => setUrl(null)}
        uploadFile={(file) => {
          setUploading && setUploading(true);
          uploadFile(user, updateTokens, file, dir).then(() => setUploading && setUploading(false));
        }}
        accept={accept}
      />
      {error && (
        <div className="invalid-feedback edit-file-error">
          {error.message || t('defaultError', 'Error')}
        </div>
      )}
    </>
  )
}

export default FileEditor;
