import { useEffect, useState } from 'react';
import dayjs from 'dayjs';

const SECOND = 1000;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;

const useCountdown = (value) => {
  const startDate = dayjs();
  const [timespan, setTimespan] = useState(startDate.add(value, 'second').diff(dayjs()));
  
  const hours = Math.floor(timespan / HOUR);
  const minutes = Math.floor((timespan / MINUTE) % 60);
  const seconds = Math.floor((timespan / SECOND) % 60);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimespan(startDate.add(value, 'second').diff(dayjs()))
    }, SECOND)

    return () => {
      clearInterval(intervalId);
    }
  }, [value]);

  return {
    hours: hours > 0 ? hours : 0,
    minutes: minutes > 0 ? minutes : 0,
    seconds: seconds > 0 ? seconds : 0,
  };
}

export default useCountdown;