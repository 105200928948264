import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import {
  Alert,
  Container,
  Card,
  Button,
  Row,
  Col,
} from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import SearchBar from '../../../widgets/SearchBar';
import UniverseFilter from '../../../widgets/filters/UniverseFilter';
import WarehouseSectorFilter from '../../../widgets/filters/WarehouseSectorFilter';
import SwitchFilter from '../../../widgets/filters/SwitchFilter';
import { TableFooter } from '../../../widgets/DataTable';
import ErrorMessage from '../../../errorMessage';
import useTableRequest from '../../../../functions/useTableRequest';
import fetchWithJWT from '../../../../functions/fetchWithJWT';
import { handleApiResponse, handlePaginatedApiResponse } from '../../../../functions/handleApiResponse';
import getQueryString from '../../../../functions/getQueryString';
import { useUserFilters } from '../../../../functions/filtersKeeper';
import Loader from '../../../loader';
import ReleaseNotification from '../../../releases/ReleaseNotification';
import QtyModal from './QtyModal';
import '../../../../stylesheets/warehouseContenantsControl.css';

const PAGE_NAME = 'contenantsControl';

const getDiscrepancyControls = (user, updateTokens, { filters, pagination }, warehouse) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const formatedFilters = { ...filters };

  const queryString = getQueryString({
    filters: formatedFilters,
    pagination,
  })
  const url = `${process.env.REACT_APP_base_URL}/api/warehouse/${warehouse}/discrepancies/controls?${queryString}`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handlePaginatedApiResponse);
};

const completeDiscrepancyControls = (user, updateTokens, { warehouse, confirmed, id, wrongSizeItem }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/warehouse/${warehouse}/discrepancies/controls/${id}`;
  return fetchWithJWT(url, {
    method: 'PUT',
    body: JSON.stringify({ confirmed, wrongSizeItem }),
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
}

const updateDiscrepancyControls = (user, updateTokens, { warehouse, id }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/warehouse/${warehouse}/discrepancies/controls/${id}/not_here`;
  return fetchWithJWT(url, {
    method: 'POST',
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
}

const updateDiscrepancyControlQty = (user, updateTokens, { qty, warehouse, confirmed, id }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/warehouse/${warehouse}/discrepancies/controls/${id}/qty`;
  return fetchWithJWT(url, {
    method: 'PUT',
    body: JSON.stringify({ quantity: qty, confirmed }),
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const ContenantControl = ({ controlItem, onComplete, setIPValue }) => {
  const isWrongSize = controlItem.discrepancyType === 'wrong_size';
  const { wrongSizes, no_contenant } = controlItem;
  return (
    <Card className="mb-3">
      <Card.Body>
        <Row>
          <Col className="info-label">
            <Trans i18nKey="warehouseContenantsControl.address">Address:</Trans>
          </Col>
          <Col>{controlItem.address}</Col>
        </Row>
        <Row>
          <Col className="info-label">
            <Trans i18nKey="warehouseContenantsControl.contenant">Contenant:</Trans>
          </Col>
          <Col>{controlItem.contenant}</Col>
        </Row>
        <Row>
          <Col className="info-label">
            <Trans i18nKey="warehouseContenantsControl.itemCode">Item code:</Trans>
          </Col>
          <Col>{controlItem.item}</Col>
        </Row>
        <Row>
          <Col className="info-label">
            <Trans i18nKey="warehouseContenantsControl.modelId">Model Id:</Trans>
          </Col>
          <Col>{controlItem.modelId}</Col>
        </Row>
        <Row>
          <Col className="info-label">
            <Trans i18nKey="warehouseContenantsControl.universeLabel">Universe label:</Trans>
          </Col>
          <Col>{controlItem.universeLabel}</Col>
        </Row>

        {isWrongSize ? (
          <>
            {wrongSizes.map(({ item }) => (
              <Row key={item}>
                <Col className="info-label">
                  <Trans i18nKey="warehouseContenantsControl.wrongSize">Suspected wrong item code:</Trans>
                </Col>
                <Col>
                  {item}
                </Col>
              </Row>
            ))}
            <Row>
              <Button
                variant="success"
                className="icon-button col control-button"
                onClick={() => onComplete({ id: controlItem.id, confirmed: false })}
              >
                <Trans i18nKey="warehouseContenantsControl.no">No</Trans>
              </Button>
              {wrongSizes.map(({ item }) => (
                <>
                  <Button
                    key={item}
                    variant="warning"
                    className="icon-button col control-button"
                    onClick={() => onComplete({ id: controlItem.id, confirmed: true, wrongSizeItem: item })}
                  >
                    <Trans i18nKey="warehouseContenantsControl.yes">Yes</Trans>
                  </Button>
                  {!no_contenant && (
                    <Button
                      variant="danger"
                      className="icon-button col control-button"
                      onClick={() => onComplete({ id: controlItem.id, notHere: true })}
                    >
                      <Trans i18nKey="warehouseContenantsControl.noContenant">No contenant</Trans>
                    </Button>
                  )}
                </>
              ))}
            </Row>
          </>
        ) : (
          <>
            <Row>
              <Col className="info-label">
                <Trans i18nKey="warehouseContenantsControl.difference">Quantity difference expected</Trans>
                { controlItem.missing > 0 ? ' ≥' : ' ≤'}
              </Col>
              <Col>
                {controlItem.missing > 0 ? '+' : null}
                {controlItem.missing}
              </Col>
            </Row>
            <Row>
              <Button
                variant="success"
                className="icon-button col control-button"
                onClick={() => onComplete({ id: controlItem.id, confirmed: false })}
              >
                <Trans i18nKey="warehouseContenantsControl.qtyIsOk">Qty is ok</Trans>
              </Button>
              <Button
                variant="warning"
                className="icon-button col control-button"
                onClick={() => setIPValue({ id: controlItem.id, value: controlItem.missing })}
              >
                <Trans i18nKey="warehouseContenantsControl.ipPlus">IP+</Trans>
              </Button>
              {!no_contenant && (
                <Button
                  variant="danger"
                  className="icon-button col control-button"
                  onClick={() => onComplete({ id: controlItem.id, notHere: true })}
                >
                  <Trans i18nKey="warehouseContenantsControl.noContenant">No contenant</Trans>
                </Button>
              )}
            </Row>
          </>
        )}
      </Card.Body>
    </Card>
  );
}

const ControlList = ({
  data,
  totals,
  loading,
  error,
  setPagination,
  onComplete,
  pagination,
}) => {
  const { t } = useTranslation();
  const [modalShow, setModalShow] = useState(false);
  const [editedControl, setEditedControl] = useState();
  const [ipDefaultValue, setIpDefaultValue] = useState();
  if (loading) {
    return <Loader/>;
  }
  if (error) {
    return <ErrorMessage error={error} />;
  }
  if (!data) {
    return;
  }
  if (data.length === 0) {
    return (
      <Alert variant="primary">
        <Trans i18nKey="warehouseContenantsControl.notFound">No contenants to control found</Trans>
      </Alert>
    );
  }

  const setIPValue = ({ id, value }) => {
    setModalShow(true);
    setEditedControl(id);
    setIpDefaultValue(value);
  };

  const changeItemQty = (value) => {
    return onComplete({
      id: editedControl,
      newQty: value,
      confirmed: true,
    });
  };

  return (
    <>
      {data.map(controlItem => (
        <ContenantControl
          key={controlItem.id}
          controlItem={controlItem}
          onComplete={onComplete}
          setIPValue={setIPValue}
        />
      ))}
      <QtyModal
        value={ipDefaultValue}
        onChange={changeItemQty}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <div className="control-mobile-pagination">
        <Button
          className="icon-button"
          onClick={() => setPagination({ ...pagination, page: pagination.page - 1})}
          disabled={pagination.page === 0}
        >
          <i className="vtmn-icon_arrow1_left"></i>
          {t('warehouseContenantsControl.previous', 'Previous')}
        </Button>
        {pagination.page + 1}
        <Button
          className="icon-button"
          onClick={() => setPagination({ ...pagination, page: pagination.page + 1})}
          disabled={pagination.page >= totals.pages - 1}
        >
          {t('warehouseContenantsControl.next', 'Next')}
          <i className="vtmn-icon_arrow1_right"></i>
        </Button>
      </div>
      <div className="control-desktop-pagination">
        <TableFooter
          pagination={pagination}
          totals={totals}
          setPagination={setPagination}
        />
      </div>
    </>
  );
};

const Control = ({ user, updateTokens }) => {
  const { t } = useTranslation();
  const { warehouse } = useParams();
  const [saving, setSaving] = useState(false);
  const [saveError, setSaveError] = useState(null);
  const initialFilters = {
    notHere: false,
  };

  const [
    { loading, error, data, totals, pagination, filters },
    fetchDiscrepancyControls, setTableFilters, setPagination
  ] = useTableRequest(getDiscrepancyControls, {
    initialState: {
      pagination: {
        page: 0,
        size: 20,
      },
      filters: initialFilters,
    }
  });

  const filtersPacking = (filters) => _.omit(filters, ['search']);
  const filtersUnpacking = (saved) => [saved];
  const {
    isLoading: isLoadingFilters,
    error: actionError,
    setFilters,
  } = useUserFilters({
    page: PAGE_NAME,
    user,
    updateTokens,
    initial: initialFilters,
    setTableFilters,
    filtersPacking,
    filtersUnpacking,
  });

  useEffect(() => {
    setSaveError(actionError);
  }, [actionError]);

  useEffect(() => {
    if (isLoadingFilters) {
      return;
    }


    fetchDiscrepancyControls(
      user,
      updateTokens,
      { filters: {
        search: filters.search,
        universeLabel: filters.universeLabel,
        sector: filters.sector,
        notHere: filters.notHere,
      }, pagination },
      warehouse,
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warehouse, filters.universeLabel, filters.search, filters.sector, filters.notHere, pagination, isLoadingFilters]);

  const onComplete = async ({ id, confirmed, wrongSizeItem, notHere, newQty }) => {
    try {
      setSaving(true);
      if (notHere) {
        await updateDiscrepancyControls(
          user,
          updateTokens,
          { warehouse, id },
        );
      } else if (!_.isNil(newQty)) {
        await updateDiscrepancyControlQty(
          user,
          updateTokens,
          { warehouse, id, confirmed, qty: newQty },
        );
      } else {
        await completeDiscrepancyControls(
          user,
          updateTokens,
          { warehouse, id, confirmed, wrongSizeItem },
        );
      }

      setPagination({ ...pagination, page: 0 });
      setSaving(false);
      setSaveError(null);
    } catch (error) {
      setSaving(false);
      setSaveError(error);
    }
  }

  return (
    <Container className="warehouseContenantsControl">
      <h2>
        <Trans i18nKey="warehouseContenantsControl.title">Picking control</Trans>
      </h2>
      <div className="filters">
        <UniverseFilter
          user={user}
          updateTokens={updateTokens}
          selected={filters.universeLabel}
          onChange={(universeLabel) => setFilters({ ...filters, universeLabel })}
          placeholder="Universe"
        />
        <div className='second-filter-row'>
          <WarehouseSectorFilter
            user={user}
            updateTokens={updateTokens}
            warehouse={warehouse}
            byDiscrepancies={false}
            selected={filters.sector}
            onChange={(sector) => setFilters({ ...filters, sector })}
            placeholder="Warehouse sector"
          />
          <SearchBar
            loading={loading || saving}
            value={filters.search}
            onChange={(search) => setFilters({ ...filters, search })}
            onRefresh={() => {
              setSaveError(null);
              setPagination({ ...pagination, page: 0 });
            }}
          />
        </div>
      </div>
      <SwitchFilter
        className="filter-not-here"
        controlId="filter-not-here"
        label={t('warehouseContenantsControl.contenantNotHere', 'Contenant not there')}
        value={filters.notHere}
        onChange={() => setFilters({
          ...filters,
          notHere: !filters.notHere,
        })}
      />
      <ControlList
        data={data}
        totals={totals}
        loading={loading || saving}
        error={error || saveError}
        onComplete={onComplete}
        setPagination={setPagination}
        pagination={pagination}
      />
      <ReleaseNotification user={user} updateTokens={updateTokens} />
    </Container>
  );
};

export default Control;

