import React from 'react';
import Item from '../item';

export const calculateItemProps = ({
  allParcels,
  itemDetails,
  movement,
  filters,
  displayFullyReceivedItems,
  filterValues,
  unexpected,
}) => {
  const myParcels = allParcels.filter(parcel => parcel.item === itemDetails.item_id)
  const myQtiesPredictedTotal = calculateTotal(myParcels, 'qty_confirmed');
  const myQtiesReceivedTotal = calculateTotal(myParcels, 'qty_read_by_store_gate');
  const myQtiesReadAtWarehouseTotal = calculateTotal(myParcels, 'qty_read_by_warehouse_gate');
  const myQtiesReadBeforeGateTotal = calculateTotal(myParcels, 'qty_read_before_warehouse_gate');

  const missingCount = myQtiesPredictedTotal - myQtiesReceivedTotal;
  const isFoundAtMovements = movement && (movement.status === 'stock_found' || movement.delta === 0);
  const isMissingAtMovements = movement && !isFoundAtMovements && (movement.delta === -missingCount);
  const isRevisedAtMovements = movement && !isFoundAtMovements && !isMissingAtMovements;

  const isBothGatesSameMissing = myQtiesPredictedTotal > myQtiesReceivedTotal && myQtiesPredictedTotal > myQtiesReadBeforeGateTotal;
  const isStoreGateZero = myQtiesReceivedTotal === 0;
  const isIncompleteBox = (
    itemDetails.pcb_quantity
    && myQtiesPredictedTotal % itemDetails.pcb_quantity === 0
    && missingCount < itemDetails.pcb_quantity * 0.5
  );
  const isControlled = isFoundAtMovements || isMissingAtMovements || isRevisedAtMovements;
  const isCheaper = filterValues && itemDetails.price <= filterValues.hideCheaper;
  const isCheaperValue = filterValues && itemDetails.price * (myQtiesPredictedTotal - myQtiesReceivedTotal) <= filterValues.hideCheaperValue;

  const visible = (
    myQtiesPredictedTotal !== myQtiesReceivedTotal
    && (itemDetails.article_flag === 'P' || itemDetails.article_flag === 'L')
    && (!filters.bothGatesMissing || (filters.bothGatesMissing && isBothGatesSameMissing))
    && (!filters.storeGateZero || (filters.storeGateZero && isStoreGateZero))
    && (!filters.hideIncompleteBoxes || (filters.hideIncompleteBoxes && !isIncompleteBox))
    && (!filters.hideControlled || (filters.hideControlled && !isControlled))
    && (!filters.hideCheaper || (filters.hideCheaper && !isCheaper))
    && (!filters.hideCheaperValue || (filters.hideCheaperValue && !isCheaperValue))
  ) || (
    unexpected
    && (!filters.hideCheaper || (filters.hideCheaper && !isCheaper))
  ) || displayFullyReceivedItems;

  return {
    visible,
    myQtiesPredictedTotal,
    myQtiesReceivedTotal,
    myQtiesReadAtWarehouseTotal,
    myQtiesReadBeforeGateTotal,
    myParcels,
    isFoundAtMovements,
    isMissingAtMovements,
    isRevisedAtMovements,
  };
};

export const DisplayItem = ({
  filters,
  filterValues,
  itemDetails,
  allParcels,
  displayFullyReceivedItems,
  warehouseReadingActivated,
  user,
  updateTokens,
  store,
  movement,
  unexpectedMatchesSize,
  unexpected,
  reception,
}) => {
  if (!itemDetails) {
    return null;
  }

  const {
    visible,
    myQtiesPredictedTotal,
    myQtiesReceivedTotal,
    myQtiesReadAtWarehouseTotal,
    myQtiesReadBeforeGateTotal,
    myParcels,
    isFoundAtMovements,
    isMissingAtMovements,
    isRevisedAtMovements,
  } = calculateItemProps({
    allParcels,
    itemDetails,
    movement,
    filters,
    displayFullyReceivedItems,
    filterValues,
    unexpected,
  });

  if (visible) {
    return (
      <div key={itemDetails.item_id} className="item-card">
        <Item
          warehouseReadingActivated={warehouseReadingActivated}
          qtiesPredictedTotal={myQtiesPredictedTotal}
          qtiesReceivedTotal={myQtiesReceivedTotal}
          qtiesReadAtWarehouseTotal={myQtiesReadAtWarehouseTotal}
          qtiesReadBeforeGateTotal={myQtiesReadBeforeGateTotal}
          item_id={itemDetails.item_id}
          item_lib={itemDetails.item_lib}
          model_id={itemDetails.model_id}
          model_lib={itemDetails.model_lib}
          brand_name={itemDetails.brand_name}
          article_flag={itemDetails.article_flag}
          pixlId={itemDetails.pixlId}
          price={itemDetails.price}
          currency={itemDetails.currency}
          family_id={itemDetails.family_id}
          family_label={itemDetails.family_label}
          sub_department_label={itemDetails.sub_department_label}
          department_label={itemDetails.department_label}
          universe_label={itemDetails.universe_label}
          parcelDetails={myParcels}
          user={user}
          updateTokens={updateTokens}
          store={store}
          movement={movement}
          isFoundAtMovements={isFoundAtMovements}
          isMissingAtMovements={isMissingAtMovements}
          isRevisedAtMovements={isRevisedAtMovements}
          unexpectedMatchesSize={unexpectedMatchesSize}
          unexpected={unexpected}
          reception={reception}
        />
      </div>
    );
  }
  return null;
};

function calculateTotal(parcels, dimension){
  const reducer = (accumulator, currentValue) => accumulator + currentValue;
  const allQties = parcels.map(pickingLine => parseInt(pickingLine[dimension], 10) || 0);
  return allQties.reduce(reducer, 0)
}
