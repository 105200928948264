import React, { useState, useEffect } from 'react';
import {
  Container,
  Table,
  Button,
  Row,
  Card,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import MDEditor from '@uiw/react-md-editor';
import Loader from '../loader';
import ErrorMessage from '../errorMessage';
import Confirm from '../widgets/Confirm';
import useRequest from '../../functions/useRequest';
import useTableRequest from '../../functions/useTableRequest';
import fetchWithJWT from '../../functions/fetchWithJWT';
import getQueryString from '../../functions/getQueryString';
import handleApiResponse, { handlePaginatedApiResponse } from '../../functions/handleApiResponse';
import { formatDate } from '../../functions/formatDate';
import { TableFooter } from '../widgets/DataTable';
import ReleaseModal from './ReleaseModal';
import '../../stylesheets/adminReleases.css';

const getReleases = (user, updateTokens, { pagination }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const queryString = getQueryString({ pagination });
  const url = `${process.env.REACT_APP_base_URL}/api/releases?${queryString}`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handlePaginatedApiResponse);
};

const postPublish = (user, updateTokens, { id }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/releases/${id}/publish`;
  return fetchWithJWT(url, {
    method: 'POST',
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const ReleaseRow = ({ release, onEdit, onPublish, user, publishing }) => {
  const { token } = user;
  const { t } = useTranslation();

  return (
    <tr>
      <td>
        <Link to={`/admin/releases/${release.id}`}>
          {release.version}
        </Link>
      </td>
      <td className="slide-image-preview">
        <img src={`${process.env.REACT_APP_base_URL}/api/files/${release.image}?token=${token}`} alt="slide" />
      </td>
      <td>{release.type}</td>
      <td>
        <MDEditor.Markdown className="mobile-markdown" source={release.summary} />
      </td>
      <td>
        {formatDate(new Date(release.creation_date))}
      </td>
      <td className="text-center inline-buttons">
        <Button
          className="tiny-button"
          size="sm"
          variant="light"
          title={t('releases.editRelease', 'Edit release')}
          onClick={onEdit}
        >
          <i className="vtmn-icon_edit"></i>
        </Button>
        <Button
          className="tiny-button icon-text-button"
          size="sm"
          variant="light"
          title={t('releases.editRelease', 'Edit release')}
          onClick={onPublish}
          disabled={release.published || publishing}
        >
          <i className="vtmn-icon_eye_on"></i>
          {!release.published
            ? <Trans i18nKey="releases.publish">Publish</Trans>
            : <Trans i18nKey="releases.published">Published</Trans>
          }
        </Button>
      </td>
    </tr>
  );
}

const AdminReleases = ({ user, updateTokens }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [editableRelease, setEditableRelease] = useState({});
  const [publishedRelease, setPublishedRelease] = useState();

  const [{
      data,
      loading,
      error,
      pagination,
      totals,
    }, // eslint-disable-next-line
    fetchReleases, setFilters, setPagination, setSort, updateDataRow
  ] = useTableRequest(getReleases);

  useEffect(() => {
    fetchReleases(user, updateTokens, { pagination });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination]);

  const setPublished = ({ releaseId }) => {
    const releaseIndex = data.findIndex((r) => r.id === releaseId);
    if (releaseIndex === -1 || errorPublish) {
      return;
    }
    updateDataRow(releaseIndex, {
      ...data[releaseIndex],
      published: true,
    });
  }

  const [{
    loading: publishing,
    error: errorPublish,
  }, doPublish] = useRequest(
    postPublish, {
      onSuccess: setPublished
    });

  

  const afterSaveRelease = () => {
    fetchReleases(user, updateTokens);
    setEditableRelease({});
  }

  const onCloseModal = () => {
    setShowModal(false);
    setEditableRelease({});
  }

  const doPublishRelease = () => {
    doPublish(user, updateTokens, { id: publishedRelease });
    setPublishedRelease();
  }

  const hasError = !!error || !!errorPublish;

  return (
    <Container className="adminGroups">
      <Row>
        <Card body className="admin-top-card">
          <Button
            className="icon-button"
            // as={Link} to="/admin/releases/new"
            onClick={() => setShowModal(true)}
          >
            <i className="vtmn-icon_edit_plus"></i>
            <Trans i18nKey="releases.addRelease">Add release</Trans>
          </Button>
        </Card>
      </Row>
      {loading && <Loader />}
      {!loading && hasError && <ErrorMessage error={error || errorPublish} />}
      {!loading && !error && data && (
        <>
          <Table hover className="releases-page">
            <thead className="thead-light">
              <tr>
                <th scope="col">
                  <Trans i18nKey="releases.headers.version">Version</Trans>
                </th>
                <th scope="col">
                  <Trans i18nKey="releases.headers.image">Image</Trans>
                </th>
                <th scope="col">
                  <Trans i18nKey="releases.headers.type">Type</Trans>
                </th>
                <th scope="col">
                  <Trans i18nKey="releases.headers.summary">Summary</Trans>
                </th>
                <th scope="col">
                  <Trans i18nKey="releases.headers.creationDate">Creation date</Trans>
                </th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {data.map((r) => (
                <ReleaseRow
                  key={r.id}
                  release={r}
                  onEdit={() => setEditableRelease(r)}
                  onPublish={() => setPublishedRelease(r.id)}
                  user={user}
                  publishing={publishing}
                />
              ))}
            </tbody>
          </Table>
          <TableFooter
            pagination={pagination}
            totals={totals}
            setPagination={setPagination}
          />
        </>
      )}
      {(!!showModal || !!editableRelease.id) && (
        <ReleaseModal
          show={showModal || !!editableRelease.id}
          onClose={onCloseModal}
          user={user}
          updateTokens={updateTokens}
          afterSave={afterSaveRelease}
          release={editableRelease}
        />
      )}
      <Confirm
        title={t('releases.publishSlideConfirm.warning', 'Warning')}
        body={t('releases.publishSlideConfirm.text', 'Are you sure you want to publish this release?')}
        show={!!publishedRelease}
        onHide={() => setPublishedRelease()}
        loading={false}
        onConfirm={doPublishRelease}
      />
    </Container>
  );
};

export default AdminReleases;
