import React from 'react';
import _ from 'lodash';
import useCountdown from '../../functions/useCountdown';

const stringifyNumber = (v) => _.padStart(v.toString(), 2, '0')

const CountDown = ({ value }) => {
  const timer = useCountdown(value);

  return `${stringifyNumber(timer.hours)}:${stringifyNumber(timer.minutes)}:${stringifyNumber(timer.seconds)}`;
}

export default CountDown;
