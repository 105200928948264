import { useState, useEffect } from 'react';
import _ from 'lodash';
import fetchWithJWT from './fetchWithJWT';
import { handleApiResponse } from './handleApiResponse';

export const saveFilters = ({
  page,
  user,
  updateTokens,
  filters,
}) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/saved_filters`;
  return fetchWithJWT(url, {
    method: 'POST',
    body: JSON.stringify({
      page,
      filters,
    }),
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    },
  })
  .then(handleApiResponse);
}

export const getFilters = ({ page, user, updateTokens }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const url = `${process.env.REACT_APP_base_URL}/api/saved_filters?page=${page}`;
  return fetchWithJWT(url, {
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    },
  })
  .then(handleApiResponse);
}

export const useUserFilters = ({ page, user, updateTokens, initial, setTableFilters, filtersPacking, filtersUnpacking }) => {
  const [localFilters, setLocalFilters] = useState({});
  const [invertedFilters, setInvertedFilters] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState();

  useEffect(() => {
    const fetchFilters = async () => {
      const fetchedFilters = await getFilters({ page, user, updateTokens });
      let [filters, invertedFilters] = [];
      if (fetchedFilters && fetchedFilters.filters) {
        [filters, invertedFilters] = filtersUnpacking(_.merge(initial, fetchedFilters.filters));
      } else {
        [filters, invertedFilters] = filtersUnpacking(initial);
      }
      setTableFilters(filters);
      setLocalFilters(filters);
      setInvertedFilters(invertedFilters);
      setIsLoading(false);
    };

    setIsLoading(true);
    fetchFilters();
  }, [page, user, updateTokens]);

  const setFilters = async (filters) => {
    try {
      setLocalFilters(filters);
      setTableFilters(filters);
      await saveFilters({
        page,
        user,
        updateTokens,
        filters: filtersPacking(filters, invertedFilters),
      });
    } catch(e) {
      setError(e);
    }
  };

  useEffect(() => {
    const doSaveFilters = async (filters) => {
      await saveFilters({
        page,
        user,
        updateTokens,
        filters,
      });
    }

    if (isLoading) {
      return;
    }

    doSaveFilters(filtersPacking(localFilters, invertedFilters))
    .catch(setError);
  }, [invertedFilters]);

  return {isLoading, error, invertedFilters, setFilters, setInvertedFilters};
}
