import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Form,
  Button,
  Modal,
} from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import TextEdit from '../../widgets/form/TextEdit';
import ErrorMessage from '../../errorMessage';
import useRequest from '../../../functions/useRequest';
import fetchWithJWT from '../../../functions/fetchWithJWT';
import handleApiResponse from '../../../functions/handleApiResponse';
import FileEditor from '../../widgets/FileEditor';
import '../../../stylesheets/adminReleases.css';

const initialContent = {
  title: '',
  description: '',
  image: '',
};

const saveSlide = ({ user, updateTokens, releaseId, content }) => {
  const { token, refreshToken, tokenExpireDate } = user;
  const slideId = content.id ? `/${content.id}` : '';
  const url = `${process.env.REACT_APP_base_URL}/api/releases/${releaseId}/slide${slideId}`;
  return fetchWithJWT(url, {
    method: content.id ? 'PUT' : 'POST',
    body: JSON.stringify(content),
    jwtOpts: {
      token,
      refreshToken,
      tokenExpireDate,
      updateTokens,
    }
  })
  .then(handleApiResponse);
};

const SlideModal = ({ user, updateTokens, show, onClose, afterSave, slide }) => {
  const { token } = user;
  const { releaseId } = useParams();
  const { t } = useTranslation();
  const [content, setContent] = useState(slide && slide.id ? slide : initialContent);

  const [{
    loading: saving,
    error: saveError,
  }, doSaveSlide] = useRequest(saveSlide, {
    onSuccess: onClose,
  });

  const onSave = async () => {
    await doSaveSlide({
      releaseId,
      content,
      user,
      updateTokens,
    });
    afterSave && afterSave();
  }

  return (
    <Modal
      show={show}
      onHide={onClose}
      size="lg"
    >
      <Modal.Header>
        <Modal.Title>
          <Trans i18nKey="releases.addSlide">Add slide</Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!!saveError && <ErrorMessage error={saveError} />}
        <div className="slide-form-wrapper">
          <div className="slide-preview">
            {!!content.image && (
              <img src={`${process.env.REACT_APP_base_URL}/api/files/${content.image}?token=${token}`} alt="slide" />
            )}
          </div>
          <Form className="slide-form">
            <TextEdit
              label={t('releases.slideForm.title', 'Title')}
              value={content.title}
              onChange={(value) => setContent({ ...content, title: value })}
            />
            <TextEdit
              label={t('releases.slideForm.description', 'Description')}
              value={content.description}
              onChange={(value) => setContent({ ...content, description: value })}
            />
            <FileEditor
              id="robot-mass-plan-file"
              user={user}
              updateTokens={updateTokens}
              url={content.image}
              setUrl={(massPlanFile) => {
                setContent({ ...content, image: massPlanFile });
              }}
              directory="releases"
            />
          </Form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" className="m-0 mr-2" onClick={onClose}>
          <Trans i18nKey="releases.cancel">Cancel</Trans>
        </Button>
        <Button
          variant="primary"
          className="m-0 mr-2"
          disabled={saving || !content.title.length || !content.description.length}
          onClick={onSave}
        >
          <Trans i18nKey="releases.confirm">Confirm</Trans>
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default SlideModal;
